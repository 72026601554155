import { range } from 'lodash-es';

export const minutes = range(0, 60, 5).map((m) => m.toString().padStart(2, '0'));

export interface Problem<T> {
  generate(): ProblemExpression<T>;

  calculate(a: T, b: T): T;

  getLabel(): string;
}

export interface ProblemExpression<T> {
  a: T;
  b: T;
  c: string;
}

export enum ProblemType {
  AddHourAndMinute = 'AddHourAndMinute',
  SubHourAndMinute = 'SubHourAndMinute',
  Add3Number = 'Add3Number',
  Sub3Number = 'Sub3Number',
  Multiply1Number = 'Multiply1Number',
}
